import './App.css';
import Home from './sites/Home';

function App() {
    return (
        <div className="App">
            <Home
            />
        </div>
    );
}

export default App;
